import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ContactSectionAddressQuery } from "../../../graphql-types"
import { P } from "../general/Typography"

interface Props {}

const contactAddressSectionQuery = graphql`
  query ContactSectionAddress {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        address_line_1
        address_line_2
      }
    }
  }
`

const ContactAddressSection: React.FC<Props> = () => {
  const data: ContactSectionAddressQuery = useStaticQuery(
    contactAddressSectionQuery
  )

  return (
    <P>
      <a
        href="https://www.google.com/maps/place/Eye+Fashion+Opticien+Leiden/@52.1611871,4.4863598,17.2z/data=!4m5!3m4!1s0x0:0x9ee253db3a19b5b9!8m2!3d52.1609988!4d4.4884575"
        target="_blank"
      >
        Adres:
        <br />
        {data.markdownRemark.frontmatter.address_line_1}
        <br />
        {data.markdownRemark.frontmatter.address_line_2}
      </a>
    </P>
  )
}

export default ContactAddressSection
