import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { ContactSectionTextQuery } from "../../../graphql-types"
import { P } from "../general/Typography"

interface Props {}

const contactSectionTextQuery = graphql`
  query ContactSectionText {
    markdownRemark(fields: { slug: { eq: "contact-text" } }) {
      frontmatter {
        section_text
      }
    }
  }
`

const ContactSectionText: React.FC<Props> = () => {
  const data: ContactSectionTextQuery = useStaticQuery(contactSectionTextQuery)

  return <P>{data.markdownRemark.frontmatter.section_text}</P>
}

export default ContactSectionText
