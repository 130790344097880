import React from "react"

const ContactMap = () => {
  return (
    <iframe
      width="100%"
      height="100%"
      style={{ border: 0 }}
      src={`https://www.google.com/maps/embed/v1/place?key=${process.env
        .GATSBY_MAPS_KEY!}
    &q=Eye+fashion+Leiden`}
    ></iframe>
  )
}

export default React.memo(ContactMap)
