import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Box } from "ticeboxes"
import ContactAddressSection from "../components/contact/ContactAddressSection"
import ContactMap from "../components/contact/ContactMap"
import ContactSectionText from "../components/contact/ContactSectionText"
import { H1, P } from "../components/general/Typography"
import Layout from "../components/layout/Layout"
import ContactEmail from "../components/contact/ContactEmail"
import Head from "../components/layout/Head"
import { graphql, useStaticQuery } from "gatsby"
import { ContactDescriptionQuery } from "../../graphql-types"
import AfspraakButton from "../components/general/AfspraakButton"

interface Props {}

const contactDescriptionQuery = graphql`
  query ContactDescription {
    markdownRemark(fields: { slug: { eq: "contact-text" } }) {
      frontmatter {
        meta_description
      }
    }
  }
`

const contact: React.FC<Props> = () => {
  const data: ContactDescriptionQuery = useStaticQuery(contactDescriptionQuery)
  return (
    <>
      <Head
        title="Contact"
        description={data.markdownRemark.frontmatter.meta_description}
      ></Head>
      <Layout>
        <Container>
          <Row className="mb-5">
            <Col xs="12" className="text-center text-md-left">
              <Box mt="5" mb="4" mb-md="5">
                <H1>Contact</H1>
              </Box>
            </Col>
            <Col xs="12" md="5" className="text-center text-md-left">
              <Box mb="4">
                <ContactSectionText />
              </Box>
              <Box mb="5" mb-md="4">
                <ContactAddressSection />
              </Box>
              <Box className="d-none d-md-block">
                <Box mb="4">
                  <P>Of maak gemakkelijk een afspraak via de Online Agenda:</P>
                  <AfspraakButton text="Maak een afspraak!" />
                </Box>
                <Box>
                  <ContactEmail />
                </Box>
              </Box>
            </Col>
            <Col
              xs="12"
              md="7"
              lg={{ size: 6, offset: 1 }}
              className="d-none d-md-block"
            >
              <Box h-100>
                <ContactMap />
              </Box>
            </Col>
          </Row>
        </Container>
        <Box className="d-block d-md-none" style={{ height: 350 }}>
          <ContactMap />
        </Box>
        <Container className="d-block d-md-none">
          <Row>
            <Col xs="12">
              <Box my="5" text-center>
                <P>Of maak gemakkelijk een afspraak via de Online Agenda:</P>
                <AfspraakButton text="Maak een afspraak!" />
              </Box>
              <Box text-center mb="5">
                <ContactEmail />
              </Box>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default contact
