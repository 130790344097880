import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { ContactEmailQuery } from "../../../graphql-types"
import { P } from "../general/Typography"

interface Props {}

const contactEmailQuery = graphql`
  query ContactEmail {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        email
      }
    }
  }
`

const ContactEmail: React.FC<Props> = () => {
  const data: ContactEmailQuery = useStaticQuery(contactEmailQuery)

  return (
    <P>
      Of stuur een email naar:
      <br />
      <Link to={`malto:${data.markdownRemark.frontmatter.email}`}>
        {data.markdownRemark.frontmatter.email}
      </Link>
    </P>
  )
}

export default ContactEmail
